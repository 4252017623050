import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, ReactNode, useRef } from "react";
import { Box, ThemeUICSSObject } from "theme-ui";
import { theme } from "../../themes/base";

export type TooltipProps = {
  boxStyleOverrides?: ThemeUICSSObject;
  /** Label used for screen readers. Added to the aria-label attribute of the tooltip. */
  accessibilityLabel: string;
  /** This is the tip content that is displayed to the user while the dropdown is open. */
  children: ReactNode;
  /** This testId is attached to the data-testid attribute of the tooltip icon. */
  iconDataTestid?: string;
  /** This is the position, relative to the icon, that the tip appears.
   * @default "bottom"
   */
  position?: "top" | "bottom";
  /** The icon to render
   * @default "React.ReactElement"
   */
  icon?: null | React.ReactElement;
};

export function Tooltip({
  accessibilityLabel,
  children,
  iconDataTestid,
  boxStyleOverrides = {},
  position = "bottom",
  icon = <FontAwesomeIcon width={20} icon={faInfoCircle} />,
}: TooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const toggle = () => setIsOpen(!isOpen);

  const keyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      toggle();
    }
  };

  const defaultTipStyle: ThemeUICSSObject = {
    background: theme.colors.darkCharcoal,
    position: "absolute",
    borderRadius: "4px",
    color: "white",
    textAlign: "center",
    lineHeight: "150%",
    fontSize: "0.875rem",
    letterSpacing: "0.18px",
    zIndex: "100",
    fontWeight: "400",
    left: "50%",
    webkitTransform: "translateX(-50%)",
    transform: "translateX(-50%)",
  };
  const topTipStyle: ThemeUICSSObject = {
    ...defaultTipStyle,
    padding: "0.3125rem",
    bottom: "1.9rem",
  };

  const bottomTipStyle: ThemeUICSSObject = {
    ...defaultTipStyle,
    padding: "0.5rem",
    top: "2.3rem",
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        marginLeft: "0.4rem",
        marginTop: "-0.2rem",
      }}
      ref={node}
    >
      <div
        style={{
          display: "inline-block",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
          cursor: "pointer",
          whiteSpace: "nowrap",
          color: theme.colors.retainBrightBlue,
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        role="tooltip"
        onMouseDown={toggle}
        onKeyDown={keyPress}
        tabIndex={0}
        data-testid={iconDataTestid}
        aria-label={accessibilityLabel}
        aria-atomic
      >
        <div style={{ position: "relative" }}>{icon}</div>
      </div>
      {isOpen && (
        <Box
          sx={
            position === "bottom"
              ? {
                  ...bottomTipStyle,
                  ...boxStyleOverrides,
                }
              : {
                  ...topTipStyle,
                  ...boxStyleOverrides,
                }
          }
        >
          {position === "bottom" && (
            <div
              style={{
                height: "0",
                zIndex: "99",
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
                borderLeft: "0.8rem solid transparent",
                borderRight: "0.8rem solid transparent",
                borderBottom: `0.6rem solid ${theme.colors.darkCharcoal}`,
                bottom: "1rem",
                width: "fit-content",
              }}
            />
          )}
          <div
            style={{
              padding: `${
                position === "bottom"
                  ? "0 0.5rem 0.5rem 0.5rem"
                  : "0.5rem 0.5rem 0 0.5rem"
              }`,
              textAlign: "left",
              minWidth: "100px",
            }}
          >
            {children}
          </div>
          {position !== "bottom" && (
            <div
              style={{
                width: "fit-content",
                height: "0",
                zIndex: "99",
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
                borderLeft: "0.8rem solid transparent",
                borderRight: "0.8rem solid transparent",
                borderTop: `0.6rem solid ${theme.colors.darkCharcoal}`,
                top: "0.9rem",
              }}
            />
          )}
        </Box>
      )}
    </div>
  );
}
