import axios, { AxiosResponse } from "axios";
import { EndpointResponse, QueryResult } from "../../pages";
import { theme } from "../../themes/base";
import { endpoints, Brand } from "../../utils/dashboardEndpoints";
import { percentageDifference } from "../../utils/percentageDifference";
import { FunnelChartPolygonProps } from "../Chart";

interface DataProps {
  baseUrl: string;
  clientId: string;
  data: any;
  initialState: any;
  session: any;
  token: any;
}

const urlPrefix = process.env.API_URL;

const batchFetch = async (
  clientId: string | string[],
  accessToken: string,
  brandId?: string | null
): Promise<any> => {
  let result: QueryResult[] = [];

  try {
    result = await axios.all(
      Object.keys(endpoints).map((queryId) => {
        return axios
          .get(
            `${urlPrefix}/api/v2/query?client_id=${clientId}&query_id=${queryId}${
              brandId ? `&brand_id=${brandId}` : ""
            }`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .catch((error) => {
            return error;
          });
      })
    );
  } catch (error: any) {
    console.error(error.toJSON());
  }

  // @ts-expect-error
  return result.map((resp: AxiosResponse) => {
    const url = resp.config.url || "";
    const query = new URL(url).search;
    const urlParams = new URLSearchParams(query);
    const queryId = urlParams.getAll("query_id").at(0);

    if (!queryId) {
      return {
        queryId,
        value: resp.data,
        error: "Query ID cannot be resolved.",
      };
    }

    if (!resp.data) {
      return {
        queryId,
        value: [],
        error: "Response was empty.",
      };
    }

    if (resp.data.error) {
      return {
        queryId,
        value: resp.data,
        error: resp.data.error,
      };
    }

    switch (queryId) {
      case "activeAccountsByMonth":
        return {
          queryId,
          value: resp.data.at(0)?.ACTIVE_ACCT_ID_CNT || null,
        };
      case "commsClickRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "Accounts with Comms Click Rate By Deliver":
                  row.COMMS_CLICK_RATE ||
                  row.ACCOUNT_TOTAL_COMMS_CLICK_RATE ||
                  0 /*temp*/,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "smsOptOutRate":
        return {
          queryId,
          value: resp.data.at(0)?.SMSOPTOUTRATE || null,
        };
      case "commsOpenRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "Comms Account Opened By Delivered Rate":
                  row.COMMS_OPEN_RATE ||
                  row.ACCOUNT_TOTAL_COMMS_OPEN_RATE ||
                  0 /*temp*/,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "channelDistribution":
        const channelMapping = {
          "ALL CHANNELS": "ALL_CHANNELS",
          "ONLY SMS": "ONLY_SMS",
          "ONLY EMAIL": "ONLY_EMAIL",
          "ONLY EMAIL AND SMS": "ONLY_EMAIL_AND_SMS",
          "ONLY EMAIL AND VM": "ONLY_EMAIL_AND_VM",
          "ONLY SMS AND VM": "ONLY_SMS_AND_VM",
          "ONLY VM": "ONLY_VM",
          "NO CHANNELS": "NO_CHANNELS",
        } as any;

        const sortedResponse = resp.data.reduce((acc: any[], data: any) => {
          const month = data.EFFECTIVE_DT_MTH || data.EFFECTIVE_DT_MONTH;
          const newKey = channelMapping[data.COMMUNICATION_GROUP];
          const value = data.ACCOUNT_ID_CNT;

          const currentMonth = acc.find((row: any) => row.month === month);

          if (!currentMonth) {
            acc.push({ month, [newKey]: value });
          } else {
            currentMonth[newKey] = value;
          }

          return acc;
        }, []);

        // Add any missing communication group keys to objects in the result array
        Object.values(channelMapping).forEach((key: any) => {
          if (sortedResponse.some((obj: any) => obj[key] !== undefined)) {
            sortedResponse.forEach((obj: any) => {
              if (!obj[key]) {
                obj[key] = "0";
              }
            });
          }
        });

        return {
          queryId,
          value: [...sortedResponse]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.month,
                ...(row.ALL_CHANNELS && {
                  "All channels": row.ALL_CHANNELS,
                }),
                ...(row.ONLY_EMAIL && {
                  "Only email": row.ONLY_EMAIL,
                }),
                ...(row.ONLY_EMAIL_AND_SMS && {
                  "Only email and SMS": row.ONLY_EMAIL_AND_SMS,
                }),
                ...(row.ONLY_EMAIL_AND_VM && {
                  "Only email and VM": row.ONLY_EMAIL_AND_VM,
                }),
                ...(row.ONLY_SMS_AND_VM && {
                  "Only SMS and VM": row.ONLY_SMS_AND_VM,
                }),
                ...(row.ONLY_SMS && {
                  "Only SMS": row.ONLY_SMS,
                }),
                ...(row.ONLY_VM && {
                  "Only VM drop": row.ONLY_VM,
                }),
                ...(row.NO_CHANNELS && {
                  "No channels": row.NO_CHANNELS,
                }),
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, sortedResponse.length > 1 ? -1 : undefined), // remove current month
        };
      case "channelDistributionMTD":
        return {
          queryId,
          value: [...resp.data].map((row: { [key: string]: any }) => {
            const channelName = {
              "ALL CHANNELS": "All channels",
              "ONLY SMS": "Only SMS",
              "ONLY EMAIL": "Only email",
              "ONLY EMAIL AND SMS": "Only email and SMS",
              "ONLY EMAIL AND VM": "Only email and VM",
              "ONLY SMS AND VM": "Only SMS and VM",
              "ONLY VM": "Only VM drop",
              "NO CHANNELS": "No channels",
            } as any;
            return {
              ...(row.COMMUNICATION_GROUP && {
                yValue: channelName[row.COMMUNICATION_GROUP],
                xValue: row.ACCOUNT_ID_CNT,
              }),
            };
          }, {}),
        };
      case "commsClickRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.COMMS_CLICK_RATE_MTD || null,
        };
      case "commsOpenRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.COMMS_OPEN_RATE_MTD || null,
        };
      case "communicationRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.COMMUNICATIONRATEMTD || null,
        };
      case "communicationRatePreviousMonth":
        return {
          queryId,
          value: resp.data.at(0)?.COMMUNICATIONRATEPREVIOUSMONTH || null,
        };
      case "paymentTrends": {
        const results = [...resp.data]
          .map((row: { [key: string]: any }) => {
            return {
              date: row.TRANSACTION_DAY,
              "Payments ($)": row.PAYMENT_TRENDS_AMT_45DAYS,
              "Payment Count": row.PAYMENT_TRENDS_CNT_45DAYS,
              y: row.PAYMENT_TRENDS_CNT_45DAYS,
              x: row.TRANSACTION_DAY,
              line0: "Payment Count",
            };
          }, {})
          .reverse(); // reverse chron.
        return {
          queryId,
          value: {
            all: results,
          },
        };
      }
      case "accountsByDelinquencyBucket":
        const formattedAccountsByDelinquencyBucket = resp.data.reduce(
          (acc: any[], data: any) => {
            const idx = acc.findIndex(
              (value) => value.EFFECTIVE_DT_MTH === data.EFFECTIVE_DT_MTH
            );
            const newKey = data.DELINQUENCY_BUCKET;

            if (idx === -1) {
              acc.push({
                EFFECTIVE_DT_MTH: data.EFFECTIVE_DT_MTH,
                [newKey]:
                  data.ACCOUNT_ID_CNT ||
                  data.ACCOUNTS_DELQ_BUCKET_6MOS /*temp*/,
              });
            } else {
              acc[idx][newKey] =
                data.ACCOUNT_ID_CNT || data.ACCOUNTS_DELQ_BUCKET_6MOS /*temp*/;
            }

            return acc;
          },
          []
        );
        return {
          queryId,
          value: {
            all: [...formattedAccountsByDelinquencyBucket]
              .map((row: { [key: string]: any }) => {
                const month =
                  row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH; /*temp*/
                delete row.EFFECTIVE_DT_MTH;
                delete row.EFFECTIVE_DT_MONTH /*temp*/;

                return {
                  month,
                  ...row,
                };
              }, {})
              .reverse() // reverse chron.
              .slice(0, -1), // remove current month
          },
        };
      case "accountsByDelinquencyBucketMTD":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                yValue: row.DELINQUENCY_BUCKET,
                xValue:
                  row.ACCOUNT_ID_CNT || row.ACCOUNTS_DELQ_BUCKET_MTD /*temp*/,
              };
            }, {})
            .sort((a, b) => a.yValue - b.yValue),
        };
      case "placementsMTD":
        return {
          queryId,
          value: resp.data.at(0)?.PLACEMENT_ID_CNT || null,
        };
      case "paymentAmountsByMonth":
        return {
          queryId,
          value: resp.data.at(0)?.PAYMENT_TRANS_SUM_AMT || null,
        };
      case "paymentCountsByMonth":
        return {
          queryId,
          value: resp.data.at(0)?.PAYMENT_TRANS_SUM || null,
        };
      case "paymentRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.PAYMENT_RATE || null,
        };
      case "emailClickRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.EMAIL_CLICK_RATE_MTD || null,
        };
      case "emailOpenRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.EMAIL_OPEN_RATE_MTD || null,
        };
      case "emailUnsubscribeRate":
        return {
          queryId,
          value: resp.data.at(0)?.ACCOUNT_EMAIL_UNSUB_RATE || null,
        };
      case "sendRatePreviousMonth":
        return {
          queryId,
          value: {
            all: resp.data,
            value: resp.data.at(0)?.ACCOUNT_EMAIL_SENT_RATE || null,
          },
        };
      case "sendRate":
        return {
          queryId,
          value: {
            all: resp.data,
            value: resp.data.at(0)?.ACCOUNT_EMAIL_SENT_RATE || null,
          },
        };
      case "maxEmailsSentToAccount":
        return {
          queryId,
          value: {
            all: [...resp.data]
              .map((row: { [key: string]: any }) => {
                return {
                  month:
                    row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                  "Emails Sent":
                    row.MAX_EMAILS_SENT || row["MAX(EMAILS_SENT_SUM)"] /*temp*/,
                };
              }, {})
              .reverse() // reverse chron.
              .slice(0, -1), // remove current month
            current:
              resp.data?.at(0)?.MAX_EMAILS_SENT ||
              resp.data?.at(0)?.["MAX(EMAILS_SENT_SUM)"] /*temp*/ ||
              null,
            prior:
              resp.data?.at(0)?.MAX_EMAILS_SENT ||
              resp.data?.at(1)?.["MAX(EMAILS_SENT_SUM)"] /*temp*/ ||
              null,
          },
        };
      case "accountEmailFunnelPreviousMonth":
        return {
          queryId,
          value: resp.data
            .map((row: { [key: string]: any }) => {
              return [
                {
                  key: "Account with Send Count",
                  sides: 4,
                  fill: theme.colors.retainViolet,
                  width: 20,
                  value: row.ACCOUNT_WITH_SEND_CNT,
                  percentage: 100,
                },
                {
                  key: "Account with Deliver Count",
                  sides: 4,
                  fill: theme.colors.primary,
                  width: 15,
                  value: row.ACCOUNT_WITH_DELIVER_CNT,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_DELIVER_CNT,
                    row.ACCOUNT_WITH_SEND_CNT
                  ),
                },
                {
                  key: "Account with Open Count",
                  sides: 4,
                  fill: theme.colors.secondary,
                  width: 10,
                  value: row.ACCOUNT_WITH_OPEN_CNT,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_OPEN_CNT,
                    row.ACCOUNT_WITH_DELIVER_CNT
                  ),
                },
                {
                  key: "Account with Click Count",
                  sides: 4,
                  fill: theme.colors.tertiary,
                  width: 5,
                  value: row.ACCOUNT_WITH_CLICK_CNT,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_CLICK_CNT,
                    row.ACCOUNT_WITH_DELIVER_CNT
                  ),
                },
              ] as FunnelChartPolygonProps[];
            }, [])
            .at(0),
        };
      case "accountEmailFunnelCurrentMonth":
        return {
          queryId,
          value: resp.data
            .map((row: { [key: string]: any }) => {
              return [
                {
                  key: "Account with Send Count",
                  sides: 4,
                  fill: theme.colors.retainViolet,
                  width: 20,
                  value: row.ACCOUNT_WITH_SEND_CNT,
                  percentage: 100,
                },
                {
                  key: "Account with Deliver Count",
                  sides: 4,
                  fill: theme.colors.primary,
                  width: 15,
                  value: row.ACCOUNT_WITH_DELIVER_CNT,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_DELIVER_CNT,
                    row.ACCOUNT_WITH_SEND_CNT
                  ),
                },
                {
                  key: "Account with Open Count",
                  sides: 4,
                  fill: theme.colors.secondary,
                  width: 10,
                  value: row.ACCOUNT_WITH_OPEN_CNT,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_OPEN_CNT,
                    row.ACCOUNT_WITH_DELIVER_CNT
                  ),
                },
                {
                  key: "Account with Click Count",
                  sides: 4,
                  fill: theme.colors.tertiary,
                  width: 5,
                  value: row.ACCOUNT_WITH_CLICK_CNT,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_CLICK_CNT,
                    row.ACCOUNT_WITH_DELIVER_CNT
                  ),
                },
              ] as FunnelChartPolygonProps[];
            }, [])
            .at(0),
        };
      case "communicationFunnelPreviousMonth":
        return {
          queryId,
          value: resp.data
            .map((row: { [key: string]: any }) => {
              return [
                {
                  key: "Account with Comms Send Count",
                  sides: 4,
                  fill: theme.colors.retainViolet,
                  width: 20,
                  value: row.ACCOUNT_WITH_COMM_SENT,
                  percentage: 100,
                },
                {
                  key: "Account with Comms Deliver Count",
                  sides: 4,
                  fill: theme.colors.primary,
                  width: 15,
                  value: row.ACCOUNT_WITH_COMM_DELV,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_COMM_DELV,
                    row.ACCOUNT_WITH_COMM_SENT
                  ),
                },
                {
                  key: "Account with Comms Open Count",
                  sides: 4,
                  fill: theme.colors.secondary,
                  width: 10,
                  value: row.ACCOUNT_WITH_COMM_OPEN,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_COMM_OPEN,
                    row.ACCOUNT_WITH_COMM_DELV
                  ),
                },
                {
                  key: "Account with Comms Click Count",
                  sides: 4,
                  fill: theme.colors.tertiary,
                  width: 5,
                  value: row.ACCOUNT_WITH_COMM_CLICK,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_COMM_CLICK,
                    row.ACCOUNT_WITH_COMM_DELV
                  ),
                },
              ] as FunnelChartPolygonProps[];
            }, [])
            .at(0),
        };
      case "communicationFunnelCurrentMonth":
        return {
          queryId,
          value: resp.data
            .map((row: { [key: string]: any }) => {
              return [
                {
                  key: "Account with Comms Send Count",
                  sides: 4,
                  fill: theme.colors.retainViolet,
                  width: 20,
                  value: row.ACCOUNT_WITH_COMM_SENT,
                  percentage: 100,
                },
                {
                  key: "Account with Comms Deliver Count",
                  sides: 4,
                  fill: theme.colors.primary,
                  width: 15,
                  value: row.ACCOUNT_WITH_COMM_DELV,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_COMM_DELV,
                    row.ACCOUNT_WITH_COMM_SENT
                  ),
                },
                {
                  key: "Account with Comms Open Count",
                  sides: 4,
                  fill: theme.colors.secondary,
                  width: 10,
                  value: row.ACCOUNT_WITH_COMM_OPEN,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_COMM_OPEN,
                    row.ACCOUNT_WITH_COMM_DELV
                  ),
                },
                {
                  key: "Account with Comms Click Count",
                  sides: 4,
                  fill: theme.colors.tertiary,
                  width: 5,
                  value: row.ACCOUNT_WITH_COMM_CLICK,
                  percentage: percentageDifference(
                    row.ACCOUNT_WITH_COMM_CLICK,
                    row.ACCOUNT_WITH_COMM_DELV
                  ),
                },
              ] as FunnelChartPolygonProps[];
            }, [])
            .at(0),
        };
      case "activeAccountMonthlyTrend":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "Active Accounts": row.ACTIVE_ACCT_ID_CNT,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "emailClickRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "Click Rate (Click/Deliver)":
                  row.ACCOUNT_EMAIL_CLICK_RATE == null
                    ? 0
                    : row.ACCOUNT_EMAIL_CLICK_RATE,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "emailOpenRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "Open Rate (Open/Deliver)":
                  row.ACCOUNT_EMAIL_OPEN_RATE == null
                    ? 0
                    : row.ACCOUNT_EMAIL_OPEN_RATE,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "paymentRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "Payment Rate": row.PAYMENT_RATE,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "monthlyEmailCadence":
        return {
          queryId,
          value: resp.data.slice(1),
        };
      case "monthlyPaymentTrends": {
        const results = [...resp.data]
          .map((row: { [key: string]: any }) => {
            return {
              month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
              "Payments ($)": row.PAYMENT_TRANS_SUM_AMT,
              "Payment Count": row.PAYMENT_TRANS_SUM,
              y: row.PAYMENT_TRANS_SUM,
              x: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
              line0: "Payment Count",
            };
          }, {})
          .reverse() // reverse chron.
          .slice(0, -1); // remove current month
        return {
          queryId,
          value: {
            all: results,
          },
        };
      }
      case "placementsAndRetractions":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                date: row.DT,
                "Daily Placements": row.TOTAL_PLACED_ACCOUNTS,
                "Daily Retractions": row.TOTAL_RETRACTED_ACCOUNTS,
              };
            }, {})
            .reverse(), // reverse chron.
        };
      case "emailEngagementMonthlyTotal":
        return {
          queryId,
          value: resp.data.slice(1),
        };
      case "smsClickRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "SMS Account Clicks By Delivered Rate":
                  (row.SMS_CLICK_RATE || row.SMS_CLICK_RATE_MTD) ??
                  null /*temp*/,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "smsClickRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.SMS_CLICK_RATE_MTD || null,
        };
      case "smsDeliveryRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "SMS Account Delivered By Sent Rate":
                  row?.SMS_DELIVERY_RATE ?? null,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "smsDeliveryRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.SMS_DELIVERY_RATE_MTD || null,
        };
      case "uniqueAccountSendsAndClick": {
        const results = [...resp.data]
          .map((row: { [key: string]: any }) => {
            return {
              month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
              "Unique Account Send": row.UNIQUE_ACCOUNT_SENDS,
              "Click Rate": row.UNIQUE_ACCOUNT_CLICK_RATE,
              y: row.UNIQUE_ACCOUNT_CLICK_RATE,
              x: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
              line0: "Click Rate",
            };
          }, {})
          .reverse() // reverse chron.
          .slice(0, -1); // remove current month
        return {
          queryId,
          value: {
            all: results,
          },
        };
      }
      case "uniqueAccountClickRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.UNIQUE_ACCOUNT_CLICK_RATE_MTD || null,
        };
      case "vmDeliveryRate":
        return {
          queryId,
          value: [...resp.data]
            .map((row: { [key: string]: any }) => {
              return {
                month: row.EFFECTIVE_DT_MTH || row.EFFECTIVE_DT_MONTH /*temp*/,
                "VM Account Delivered by Sent Rate": row.VM_DELIVERY_RATE,
              };
            }, {})
            .reverse() // reverse chron.
            .slice(0, -1), // remove current month
        };
      case "vmDeliveryRateMTD":
        return {
          queryId,
          value: resp.data.at(0)?.VM_DELIVERY_RATE_MTD || null,
        };
      case "vmOptOutRate":
        return {
          queryId,
          value: resp.data.at(0)?.VM_OPT_OUT_RATE || null,
        };
      default:
        return {
          queryId,
          value: resp.data || [],
        };
    }
  });
};

const getKeyValueDataFromQueryId = (data: any, queryId: string) => {
  const query = data.find((result: QueryResult) => result.queryId === queryId);
  return {
    value: query.value || null,
    error: query.error || null,
  };
};

const fetchClient = (clientId: string, accessToken: string): Promise<any> => {
  return axios.get(`${urlPrefix}/api/v2/clients/${clientId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const fetchData = async (
  clientId: string | string[],
  accessToken: string,
  brandId?: string | null
) => {
  const apiResponse = await batchFetch(clientId, accessToken, brandId);
  return apiResponse.reduce(
    (accumulator: EndpointResponse, value: EndpointResponse) => {
      return {
        ...accumulator,
        [value.queryId]: getKeyValueDataFromQueryId(apiResponse, value.queryId),
      };
    },
    {}
  );
};

export const withData = async (props: DataProps) => {
  const data = await fetchData(
    props.clientId,
    props.session?.accessToken ?? ""
  );
  let brands = [];
  let smsMetrics = false;
  let vmMetrics = false;

  if (props.clientId) {
    try {
      const clientApiResponse = await fetchClient(
        props.clientId,
        props.session.accessToken
      );
      smsMetrics = clientApiResponse?.data?.properties?.smsMetrics;
      vmMetrics = clientApiResponse?.data?.properties?.vmMetrics;
      brands = clientApiResponse?.data?.brands.filter(
        (brand: Brand) =>
          brand.properties.active === true &&
          brand.reference.toUpperCase() !==
            clientApiResponse?.data?.reference.toUpperCase() &&
          brand.name.toUpperCase() !==
            clientApiResponse?.data?.name.toUpperCase()
      );
    } catch (error: any) {
      console.error(error.toJSON());
    }
  }

  return {
    ...props,
    data,
    brands,
    smsMetrics,
    vmMetrics,
  };
};
